import React from "react"

import Layout from "../../components/layout"
import Grid from "../../components/grid";
import SEO from "../../components/seo"

const GridPage = () => (
    <Layout>
        <SEO title="Grid" />
        <Grid />
    </Layout>
)

export default GridPage
